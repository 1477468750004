import React from 'react'
import "./FixedWhatshappButton.scss";

const FixedWhatshappButton = () => {
  return (
    <>
      <div className="fixed_whatsapp_button">
        <a
          href="https://wa.me/+919730858444"
          rel="noreferrer"
          className="btn-whatsapp"
          target="_blank"
        >
          <div className="btn-whatsapp__ico">
            <i className="fab fa-whatsapp"></i>
          </div>
        </a>
      </div>
    </>
  );
}

export default FixedWhatshappButton