const FooterData = [
  // {
  //   title: "company",
  //   submenu: [
  //     {
  //       title: "Home",
  //       link: "/",
  //     },
  //     {
  //       title: "About",
  //       link: "/about-us",
  //     },
  //     {
  //       title: "Medical Council",
  //       link: "medical-council",
  //     },
  //     {
  //       title: "Contact Us",
  //       link: "/contact",
  //     },
  //   ],
  // },
  {
    title: "Degree",
    submenu: [
      {
        title: "MBA",
        link: "/mba",
      },
      {
        title: "BBA",
        link: "/bba",
      },

      // {
      //   title: "BA LLB",
      //   link: "/ba-llb",
      // },

      {
        title: "LLB / BBA LLB",
        link: "/bba-llb",
      },

      {
        title: "B-Tech",
        link: "/b-tech",
      },
      {
        title: "Diploma",
        link: "/diploma",
      },
    ],
  },
  {
    title: "Medical",
    submenu: [
      {
        title: "MBBS",
        link: "/mbbs",
      },
      {
        title: "BAMS",
        link: "/bams",
      },
      {
        title: "BHMS",
        link: "/bhms",
      },
      {
        title: "BDS",
        link: "/bds",
      },
      {
        title: "B.sc Nursing",
        link: "/nursing",
      },
      {
        title: "B Pharma/Pharm D",
        link: "/pharmacy",
      },
    ],
  },
  {
    title: "Medical",
    submenu: [
      {
        title: "Bio Tech/B.Sc Bio Tech",
        link: "/biotech",
      },
      {
        title: "B.Sc Radiology",
        link: "/radiology",
      },
      {
        title: "B.Sc Microbiology",
        link: "/microbiology",
      },
      {
        title: "Bachelor in Physio Therapy",
        link: "/physio-therapy",
      },
      {
        title: "B.Sc Forensic",
        link: "/forensic",
      },
    ],
  },
  // {
  //   title: "Services",
  //   submenu: [
  //     {
  //       title: "Brand experience",
  //       link: "/page/our-services",
  //     },
  //     {
  //       title: "e-Commerce website",
  //       link: "/page/our-services",
  //     },
  //     {
  //       title: "Content writing",
  //       link: "/page/our-services",
  //     },
  //     {
  //       title: "Marketing strategy",
  //       link: "/page/our-services",
  //     },
  //   ],
  // },
  // {
  //   title: "Resources",
  //   submenu: [
  //     {
  //       title: "Theme guide",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "Support desk",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "What we offer",
  //       link: "/page/what-we-offer",
  //     },
  //     {
  //       title: "Company history",
  //       link: "/page/our-story",
  //     },
  //   ],
  // },
  // {
  //   title: "Categories",
  //   submenu: [
  //     {
  //       title: "For men",
  //       link: "/shop/shop-wide",
  //     },
  //     {
  //       title: "For woman",
  //       link: "/shop/shop-wide",
  //     },
  //     {
  //       title: "Accessories",
  //       link: "/shop/shop-wide",
  //     },
  //     {
  //       title: "Collections",
  //       link: "/shop/shop-wide",
  //     },
  //   ],
  // },
  // {
  //   title: "Adavantages",
  //   submenu: [
  //     {
  //       title: "Free delivery",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "100 days refund",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "Multiple payments",
  //       link: "/page/faq-s",
  //     },
  //     {
  //       title: "Sustainable",
  //       link: "/page/faq-s",
  //     },
  //   ],
  // },
];

export default FooterData;
