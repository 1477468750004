import React from "react";
// import "./FixedInstagramButton";
import "./FixedInstagramButton.scss";
export const FixedInstagramButton = () => {
  return (
    <>
      <div className="fixed_instagram_button">
        <a
          href="https://instagram.com/counselling_india?igshid=OGQ5ZDc2ODk2ZA=="
          rel="nofollow"
          target="blank"
          className="btn-instagram"
        >
          <div className="btn-instagram__ico">
            <i class="fa-brands fa-instagram"></i>
          </div>
        </a>
      </div>
    </>
  );
};
