import React from "react";
import "./FixedFacebookButton.scss";
export const FixedFacebookButton = () => {
  return (
    <>
      <div className="fixed_facebook_button">
        <a
          href="https://www.facebook.com/indiacounseling?mibextid=ZbWKwL"
          rel="nofollow"
          target="blank"
          className="btn-facebook"
        >
          <div className="btn-facebook__ico">
            <i class="fa-brands fa-facebook"></i>
          </div>
        </a>
      </div>
    </>
  );
};
