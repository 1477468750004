const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },

  {
    title: "Streams",

    // dropdown: [
    //   {
    //     title: "PCM",
    //     link: "/pcm",
    //   },
    //   {
    //     title: "PCB",
    //     link: "/pcb",
    //   },
    //   {
    //     title: "Commerce",
    //     link: "/commerce",
    //   },
    //   {
    //     title: "Arts",
    //     link: "/arts",
    //   },
    //   {
    //     title: "Diploma",
    //     link: "/diploma",
    //   },
    // ],
    megamenu: [
      {
        title: "Degree Courses",
        dropdown: [
          {
            title: "MBA",
            link: "/mba",
          },
          {
            title: "BBA",
            link: "/bba",
          },

          // {
          //   title: "BA LLB",
          //   link: "/ba-llb",
          // },

          {
            title: "LLB / BBA LLB",
            link: "/bba-llb",
          },

          {
            title: "B-Tech",
            link: "/b-tech",
          },
          {
            title: "Diploma",
            link: "/diploma",
          },
        ],
      },
      {
        title: "Medical Courses",
        dropdown: [
          {
            title: "MBBS",
            link: "/mbbs",
          },
          {
            title: "BAMS",
            link: "/bams",
          },
          {
            title: "BHMS",
            link: "/bhms",
          },
          {
            title: "BDS",
            link: "/bds",
          },
          {
            title: "B.sc Nursing",
            link: "/nursing",
          },
          {
            title: "B Pharma/Pharm D",
            link: "/pharmacy",
          },
        ],
      },
      {
        title: ".",
        dropdown: [
          {
            title: "Bio Tech/B.Sc Bio Tech",
            link: "/biotech",
          },
          {
            title: "B.Sc Radiology",
            link: "/radiology",
          },
          {
            title: "B.Sc Microbiology",
            link: "/microbiology",
          },
          {
            title: "Bachelor in Physio Therapy",
            link: "/physio-therapy",
          },
          {
            title: "B.Sc Forensic",
            link: "/forensic",
          },
        ],
      },
    ],
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Medical Counselling",
    link: "medical-counselling",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

export default HeaderDataAlpha;
