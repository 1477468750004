import React, { useState } from "react";

/** libraries */
import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

/** components */
import Header, { HeaderNav, Menu } from "./Header";
import Buttons from "../Button/Buttons";
import './HeaderMain.scss'
const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav
          fluid="fluid"
          theme="light"
          expand="lg"
          className="border-b !bg-white border-[#0000001a] px-[35px] py-[10px] lg:px-[15px] md:py-[18px] sm:px-0"
        >
          <Col sm={6} lg={2} xs="auto" className="me-auto ps-0">
            <Link aria-label="logo home" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  // width="111"
                  // height="36"
                  loading="lazy"
                  src="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo.webp"
                  data-rjs="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  // width="111"
                  // height="36"
                  loading="lazy"
                  src="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo-md.webp"
                  data-rjs="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo-md.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  // width="111"
                  // height="36"
                  loading="lazy"
                  src="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo-md.webp"
                  data-rjs="/assets/img/webp/MedicalCounsellingIndia/logo/brand-logo-md.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`order-last md:ml-[17px] md:px-[15px] ${
              !open ? "collapsed" : ""
            }`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="col-auto justify-center menu-order finance-header-menu px-lg-0 col-lg-6"
            in={open}
          >
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
          <Col xs="auto" lg={2} className="text-right pe-0 flex col-lg-4  col-xl-3 gap-3 items-center">
            {/* <Buttons
              to="/contact"
               className="rounded-[4px] font-medium font-serif uppercase shadow-[0_0_30px_rgba(0,0,0,0.08)] sm:hidden"
              themeColor={["#1a1c45", "#1a1c45"]}
              size="xs"
              color="#fff"
              title="<i   className="fa-solid fa-phone"></i> 72817728281"
            /> */}

            <Buttons
              ariaLabel="Contact"
              href="https://wa.me/+919730858444"
              className="font-medium font-serif uppercase rounded-[4px]  contact-btn "
              themeColor="#2ED47C"
              color="#2ED47C"
              size="md"
              title="Whatsapp Us"
              icon="fab fa-whatsapp fa-lg"
              iconPosition="after"
              target="_blank"
            />

            <a href="tel:+919730858444">
              <button className="flex items-center gap-2 text-[#f78c1f] hover:text-[#000]  transition  py-2 rounded-sm cursor-pointer">
                <i className="fa-solid fa-phone rotate-90"></i> +91 9730858444
              </button>
            </a>
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
