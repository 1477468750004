export function contactUs(contactFormData) {
    return new Promise((resolve, reject) => {
      fetch("https://us-central1-teckat-clients.cloudfunctions.net/contactUs", {
        method: "POST",
        body: JSON.stringify(contactFormData),
        headers: { "content-type": "application/json" },
      }).then(async (res) => {
        const data = await res.json();
        // console.log(res.status);
        if (res.status >= 400) {
          reject(data);
        } else {
          resolve(data);
        }
      });
    });
  }
  
  export function requestCallback(requestFormData) {
    return new Promise((resolve, reject) => {
      fetch("https://us-central1-teckat-clients.cloudfunctions.net/reqCallBack", {
        method: "POST",
        body: JSON.stringify(requestFormData),
        headers: { "content-type": "application/json" },
      }).then(async (res) => {
        const data = await res.json();
        // console.log(res.status);
        if (res.status >= 400) {
          reject(data);
        } else {
          resolve(data);
        }
      });
    });
  }
  