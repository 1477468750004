import "./FixedCallButton.scss";

// fixed call button start
const FixedCallButton = () => {
  return (
    <>
      <div className="fixed_call_button">
        <a href="tel:+919730858444" rel="nofollow" className="btn-call">
          <div className="btn-call__ico">
            <i className="fas fa-phone-alt"></i>
          </div>
        </a>
      </div>
    </>
  );
};

export default FixedCallButton;
